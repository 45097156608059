  .home-box {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    justify-content: center;
    background-color:  #333;
    color: #bb3d3d; /* Change the text color to white */
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    left: 20px; /* Position at the left */
    top: 20px; /* Adjust the top position as needed */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 2; /* Increase the z-index value */
  }

  .home-box-extention {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    justify-content: center;
    background-color:  #686868;
    color: #ffffff; /* Change the text color to white */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 30px; /* Position at the left */
    top: 80px; /* Adjust the top position as needed */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Lower the z-index value */
  }

  .home-box-extention-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #686868;
    color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 80px;
    top: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-right: 10px;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
}

.digram-name-button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 20px; /* Add left padding to shift text right */
}

.diagram-title {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    color: white;
}



  
  .home-button {
    background-color:  transparent;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    width: 38px; /* Set a fixed width */
    height: 38px; /* Set a fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 6px; /* Adjust margin */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
  }

  
  .home-button:hover {
    background-color: #193f1400; /* Change to the desired hover color */
    color: #fff; /* Change the text color to white */
    transform: scale(1.1); /* Increase the scale for a stronger effect */
    transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
  }