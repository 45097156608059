.separator-line {
    border-top: 0.5px solid #ddd;
    margin: 10px 0;
    width: 60%;
  }
  

  .element-toolbar {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    justify-content: center;
    background-color:  #333;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 20px; /* Position at the left */
    top: 25%; /* Adjust the top position as needed */
  
  }