.footer {
    background-color: transparent;
    color: #2B5B6A;
    text-align: center;
    padding: 1.5rem 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard */
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 1rem;
}

.footer-section {
    flex: 1;
    margin: 1rem;
    min-width: 200px;
}

.footer-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.footer-section p, .footer-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul li {
    margin: 0.5rem 0;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #ff6347;
}

.social-links {
    display: flex;
    justify-content: center;
    padding: 0;
}

.social-links li {
    margin: 0 0.5rem;
}

.footer-bottom {
    margin-top: 1rem;
    font-size: 0.875rem;
}

.footer-bottom p {
    margin: 0;
}

.footer-bottom-right {
    margin-top: 1rem;
    font-size: 0.7rem;
    position: absolute;
    right: 100px;
    bottom: 25px;
}

.footer-bottom-right p {
    margin: 0;
}