.home-container {
    width: 100%;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard */
  }
  
.titlebox {
  margin-top: 10px;
}

.titlebox-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titlebox-left {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-grow: 1; /* Allow the content to take up available space */
  text-align: center; /* Center the text */
  width: 50px;
}

.titlebox-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1; /* Allow the content to take up available space */
  text-align: center; /* Center the text */
}

.titlebox-right {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-grow: 1; /* Allow the content to take up available space */
  text-align: center; /* Center the text */
  width: 50px;
  font-size: small;
  color: #2B5B6A;
}

.title {
  margin-left: 10px;
  font-size: 40px;
  cursor: default;
  color: #75b6ee;
}

.menu ul {
    list-style-type: none;
    padding: 0;
    margin-right: 120px;
    margin-left: 40px;
    display: flex; /* Display the menu items horizontally */
    justify-content: flex-end; /* Align menu items to the right */
}

.menu li {
    margin-left: 10px;
}

.menu a {
    text-decoration: none;
    color: #ffffff;
}

.menu a:hover {
    color: #555;
}


.maincontainer {
    background-color: #14244f49;
    width: calc(100%-80px); /* Calculate width minus 80px for left and right margins */
    height: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    align-items: center;
    padding: 40px
}


.maincontainer-title {
    margin-top: 0; /* Reset default margin for h2 */
    font-size: 16px;
    position: absolute;
    left: 20px; /* Set the left position to 20px */
    top: 10px;
    cursor: default;
  }

  .library-container {
    display: flex;
    width: 90%; /* Calculate width minus 80px for left and right margins */
    height: auto;
    position: relative;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
  }

  .push-button {
    display: flex;
    background-color:#467ba3;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    margin: 20px;
  }
  
  .push-button:hover {
    background-color: #3b688a;
    transform: scale(1.1); /* Increase the scale for a stronger effect */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
  }

  .library {
    width: 80%; /* Calculate width minus 80px for left and right margins */
    height: 80px;
    position: relative;
    padding: 20px;
    flex-shrink: 0;
  }

  .library-element {
    display: flex;
    background-color:#467ba3;
    color: white;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 15px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-shrink: 0;
  }
  
  .library-element:hover {
    background-color: #234158;
    transition: background-color 0.4s; /* Add color transition */
  }

  .space-title-container{
    padding: 40px;
    font-size: 30px;
  }

