  .top-right-menu {
    display: flex;
    flex-direction: row; /* Stack buttons vertically */
    align-items: center;
    justify-content: center;
    background-color:  transparent;
    color: #ffffff; /* Change the text color to white */
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    right: 25px; /* Position at the left */
    top: 25px; /* Adjust the top position as needed */
    z-index: 2; /* Increase the z-index value */
    gap: 20px;
  }

  .go-to-app-box {
    width: 150px;
    background-color:  transparent;
    color: #ffffff; /* Change the text color to white */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    z-index: 2; /* Increase the z-index value */
  }

  .app-button {
    background-color:  transparent;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    height: 38px; /* Set a fixed height */
    margin: 6px 6px; /* Adjust margin */
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
    font-family: 'Nunito', sans-serif; /* Apply font family if needed */
  }

  .login-button {
    background-color:  transparent;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    width: 32px; /* Set a fixed width */
    height: 32px; /* Set a fixed height */
    margin: 6px 6px; /* Adjust margin */
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
  }

  
  .app-button:hover {
    background-color: #193f1400; /* Change to the desired hover color */
    color: #fff; /* Change the text color to white */
    transform: scale(1.1); /* Increase the scale for a stronger effect */
    transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
  }

  .login-button:hover {
    background-color: #193f1400; /* Change to the desired hover color */
    color: #fff; /* Change the text color to white */
    transform: scale(1.1); /* Increase the scale for a stronger effect */
    transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
  }

  .body {
    display: flex;
    position: absolute;
    flex-direction: column; /* Stack buttons vertically */
    background-color: #c4c4c400;
    top: 200px;
    left: 120px;
    width: 60%;
    height: 60%;
    border-radius: 5px;
    text-align: left;
    padding: 2rem;
  }

  .body h1 {
    font-size: 3.2rem;
    font-weight: 100;
    margin: 0;
    color: hsl(var(--foreground));
  }

  .body p {
    font-size: 1.2rem;
    margin: 1.2rem 0;
    color: hsl(var(--muted-foreground));
}

  .image {
    display: flex;
    position: absolute;
    background-color: #b31f1f00;
    top: 220px;
    right: 120px;
    width: 40%;
    height: auto;
    border-radius: 5px;
    text-align: center;
    padding: 2rem;
    transform-origin: center;  /* Center transform origin */
    transform: perspective(1000px) rotateY(-15deg) rotateX(15deg) rotateZ(5deg);  /* 3D rotate */
  }