@import "~font-awesome/css/font-awesome.min.css";
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


body {
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
  height: 100%;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    hsl(var(--background)) 0%,
    hsl(var(--background-darker-end)) 70%
);
  font-family: 'Nunito', sans-serif; /* Apply font family if needed */
  color: hsl(var(--foreground));
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: top;
  height: 100vh;
}

.modern-button {
  background-color:#467ba3;
  color: white;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  font-size: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 80px;
}

.modern-button:hover {
  background-color: #3b688a;
  transform: scale(1.1); /* Increase the scale for a stronger effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
}

.diagram-create-button {
  background-color: #467ba3;
  color: white;
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.diagram-create-inactive-button {
  background-color: #467ba352;
  color: #467ba39f;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.diagram-create-button:hover {
  background-color:  #3b688a;
  transform: scale(1.1); /* Increase the scale for a stronger effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
}

.framework-library {
  background-color: #467ba31e;
  width: calc(100%-80px); /* Calculate width minus 80px for left and right margins */
  height: 120px;
  display: flex;
  flex-direction: column;
  padding: 20px; /* Add padding for better spacing */
  position: relative;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.library-title {
  margin-top: 0; /* Reset default margin for h2 */
  font-size: 20px;
  position: absolute;
  left: 20px; /* Set the left position to 20px */
}

.library-content {
  width: auto; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; /* Align items to the left */
  position: absolute;
  left: 80px; /* Set the left position to 20px */
  top: 40px;
}

.frameworks-list {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  align-items: flex-start;
}

.framework-library-element {
  width: 80px;
  height: 80px;
  color: #ffffff;
  font-size: 12px;
  background-color:  #265d86;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  border-radius: 10px;
}

.framework-library-element:hover {
  background-color:  #5c768a;
  transform: scale(1.1); /* Increase the scale for a stronger effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
}

.link-text {
  text-decoration: none;
  color: inherit;
}

.diagram-list {
  background-color: #467ba31e;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px; /* Add padding for better spacing */
  position: relative;
  left: 40px; /* Set the left position to 20px */
  right: 40px;
  top: 100px;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.diagram-link-container{  
  background-color: #467ba3a6;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 180px;
  margin: 20px
}


.diagram-view-container{  
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.canvas-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #e4e4e4;
  overflow: hidden;
  position: relative;
  z-index: 0; /* Ensure the side panel is on top */
}

button {
  margin: 20px;
}

.edit-toolbar {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center;
  justify-content: center;
  background-color:  #333;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 20px; /* Position at the left */
  bottom: 5%; /* Adjust the top position as needed */
}

.element-toolbar-horizontal {
  display: flex;
  flex-direction: row; /* Stack buttons vertically */
  align-items: center;
  justify-content: center;
  background-color:  #333;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 30px; /* Adjust the top position as needed */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.7);
}

.toolbar-button {
  background-color:  #193f1400;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 38px; /* Set a fixed width */
  height: 38px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 6px; /* Adjust margin */
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
}

.toolbar-button:hover {
  background-color: #2b8888; /* Change to the desired hover color */
  color: #fff; /* Change the text color to white */
  transform: scale(1.1); /* Increase the scale for a stronger effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s, color 0.4s, transform 0.4s, box-shadow 0.4s; /* Add color transition */
}

.toolbar-button.selected {
  background-color: #ffffff; /* Change to the desired hover color */
  color: #616161; /* Change the text color to white */
  transform: scale(1.1); /* Increase the scale for a stronger effect */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.6); /* Adjust the values for the inset shadow */
}

.toolbar-button:active {
  transform: scale(0.95);
  box-shadow: none;
}

.infoTextBar{
  width: 100%;
  background-color: #7ca1258e; 
  z-index: 2; /* Ensure the side panel is on top */
  position: absolute; /* Ensure position is set */
  bottom: 0px;
}


.title-box {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  height: 20px;
  align-items: center;
  justify-content: center;
  background-color:  #193f144b;
  color: #ccc; /* Change the text color to white */
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  left: 50%; /* Position at the left */
  top: 20px; /* Adjust the top position as needed */
  font-family: 'Nunito', sans-serif; /* Apply font family if needed */
  font-style: italic;
  font-weight: 200; /* currently not working ????*/
}


.addElementField {
  color: #333; /* Change the text color to white */
  display: flex;
  flex-direction: row; /* Stack buttons vertically */
  align-items: center;
  justify-content: center;
  background-color:  #2b8888;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 40%; /* Position at the left */
  top: 20%; /* Adjust the top position as needed */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.7);
  z-index: 2; /* Ensure the side panel is on top */
}

.field-button {
  background-color:  transparent;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 38px; /* Set a fixed width */
  height: 38px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 6px; /* Adjust margin */
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;
}