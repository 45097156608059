.scrollableLibraryContainer {
    position: flex;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    background-color:transparent;
  }
  
  .libraryItems {
    display: flex;
    overflow: hidden;
    scroll-snap-type: x mandatory;
    gap: 50px; /* Adjust as needed */
    padding: 20px;
    padding-left: 50px; /* Adjust as needed */
    padding-right: 50px; /* Adjust as needed */
  }

  .library-entry {
    cursor: pointer;
    transition: scale 0.4s ease, box-shadow 0.4s ease;
    flex-shrink: 0; /* Ensure each item takes up full width */
    box-shadow: 0px 20px 17px rgb(0 0 0 / 50%);
    border-radius: 25px;
  }

  .library-entry:hover {
    scale: 0.98;
    box-shadow: 0px 15px 12px rgb(0 0 0 / 60%);
  }

  .library-element {
    display: flex;
    background-color:#467ba3;
    color: white;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    text-decoration: none;
    color: inherit;
  }
  
  .library-element:hover {
    background-color: #234158;
    transition: background-color 0.4s; /* Add color transition */
  }
  
  .scrollButtonContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }

  .scrollButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: none;
    background-color: #7598A4;
    box-shadow: 0 4px 8px rgb(0 0 0 / 50%);
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .scrollButtonLeft {
    left: 10px;
    flex-shrink: 0;
    pointer-events: auto;
  }

  .scrollButtonRight {
    right: 10px;
    flex-shrink: 0;
    pointer-events: auto;
  }

  .scrollButtonLeft[aria-hidden="true"],
  .scrollButtonRight[aria-hidden="true"] {
    opacity: 0; /* Hide the buttons initially */
  }

  .scrollButtonLeft:not([aria-hidden="true"]:hover),
  .scrollButtonRight:not([aria-hidden="true"]:hover) {
    opacity: 1; /* Semi-transparent when not hovered */
  }

  .scrollButtonLeft[aria-hidden="true"] + .scrollButtonRight {
    margin-left: auto; /* Push the right button to the right */
  }

  .scrollButtonRight[aria-hidden="true"] + .scrollButtonLeft {
    margin-right: auto; /* Push the left button to the left */
  }

  